<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks" />

    <f-container topBottom>
      <GlobalInstructions />

      <GlobalFixButtons />

      <UserList />
    </f-container>
  </div>
</template>

<script>
import GlobalFixButtons from '../../../components/views/settings/functions/usersCheck/GlobalFixButtons.vue';
import GlobalInstructions from '../../../components/views/settings/functions/usersCheck/GlobalInstructions.vue';
import UserList from '../../../components/views/settings/functions/usersCheck/UserList.vue';

export default {
  components: {
    GlobalFixButtons,
    GlobalInstructions,
    UserList,
  },
  computed: {
    prevLinks() {
      return [
        {
          title: this.$t('labels.settings'),
          route: {
            name: 'settings',
          },
        },
      ];
    },
    title() {
      return this.$t('settings.names.checkUsers');
    },
  },
};
</script>

<template>
  <div>
    <!-- global instructions -->
    <div class="cu-gi-btns">
      <button @click="fix('typeUndefined')">Fix - Type Undefined</button>
      <button @click="fix('hasOldDefinitions')">
        Fix - Has Old Definitions
      </button>
      <button @click="fix('invalidNullMaster')">
        Fix - Invalid Null Master
      </button>
      <button @click="fix('studentWithoutMaster')">
        Fix - Student Without Master
      </button>
    </div>
  </div>
</template>

<script>
import { fix as userFix } from '../../../../../services/users';
export default {
  methods: {
    async fix(errorType) {
      this.$store.commit('system/loading', true);
      await userFix(errorType, null);
      setTimeout(() => {
        this.$hub.$emit('checkusers');
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
.cu-gi-btns {
  margin-bottom: 12px;

  button {
    background-color: #02172b;
    color: rgb(221, 221, 221);
    margin-right: 10px;
    padding: 4px 24px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    opacity: 0.8;

    &:hover {
      color: white;
      opacity: 1;
    }
  }
}
</style>

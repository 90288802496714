<template>
  <div>
    <lms-default-loader v-if="isLoading" />
    <div class="cu-wrapper" v-if="!isLoading">
      <div v-for="user in users" :key="user.userID" class="cu-user-card">
        name:
        <strong> {{ user.data.name }} </strong><br />
        email:<strong>{{ user.data.email }}</strong> <br />
        errors: <strong class="cu-errors">{{ user.problems }}</strong>
      </div>
      <div v-if="!users.length">no users with errors</div>
    </div>
  </div>
</template>

<script>
import { check } from '../../../../../services/users';
export default {
  data: () => ({
    users: [],
  }),
  async created() {
    this.$hub.$on('checkusers', () => {
      this.loadUsers();
    });

    this.loadUsers();
  },
  methods: {
    async loadUsers() {
      const result = await check();
      this.users = result.users;
      this.$store.commit('system/loading', false);
    },
  },
};
</script>

<style lang="scss">
.cu-user-card {
  background-color: #ebebeb;
  padding: 10px 20px;
  margin-bottom: 12px;

  .cu-errors {
    color: #c70000;
  }
}
</style>
